/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import {
  Eye as ShowPassword,
  EyeOff as HidePassword
} from 'react-feather';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import AppContext from 'src/components/AppContext';
import TrainingContext from 'src/contexts/TrainingContext';
import UserContext from 'src/contexts/UserContext';
import { useSocket } from 'src/contexts/SocketContext';
import Guest from './Guest';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = (params) => {
  const redirectToPublic = params.redirectToPublic;
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const socket = useSocket();

  const loginContext = useContext(AppContext);
  const trainingContext = useContext(TrainingContext);
  const userContext = useContext(UserContext);

  const redirectTo = query.get('redirect');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // let bmsApp = new App(); 
  // updateAppState = ()=>{
  //   let bmsApp = new App(); 
  //   bmsApp.checkAuth();
  // }
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values, { setSubmitting }) => {
              console.log("login sbmit");
              const postData = {
                email: values.email,
                password: values.password,
              };
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                }
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, postData, options)
                .then((res) => {
                  const respData = res.data.data;
                  // console.log(res);
                  // console.log(res.data.data.tokens);
                  if (respData.tokens) {
                    // alert(respData.tokens.access);
                    // localStorage.setItem('bms_user', JSON.stringify(res.data));
                    const accessToken = respData.tokens.access.token;
                    const accessTokenExpiry = respData.tokens.access.expires;
                    inLocalStorageJwt.setAuthToken(accessToken, accessTokenExpiry);
                    const refreshToken = respData.tokens.refresh.token;
                    const refreshTokenExpiry = respData.tokens.refresh.expires;
                    inLocalStorageJwt.setRefreshToken(refreshToken, refreshTokenExpiry);
                    loginContext.updateAuth(true);
                    userContext.userData=respData.user;
                    inLocalStorageJwt.setLoggedinUserData(respData.user);
                    inLocalStorageJwt.setUserPermissions(respData.userPermissions);
                    socket.emit('userLogin', respData.user.id);
                    toast.success(`${res.data.message}`, {
                      position: 'top-right',
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    if (res.data.status === true) {
                      if(respData.user.is_verified === false){
                      // console.log(respData.user);
                        navigate({
                          pathname: '/auth/verify-phone',
                          // search: `?phoneNum=${respData.user.phone}&name=${respData.user.fname}`,
                          search: `?${createSearchParams({phoneNum:respData.user.phone, name: respData.user.fname})}`,
                        });
                        return ;
                        // navigate(`/auth/verify-phone?phoneNum=${respData.user.phone}&name=${respData.user.fname}`, { replace: true });
                      }
                      if(redirectToPublic){
                        trainingContext.setShowAuth(false);
                        navigate(redirectToPublic, { replace: true });
                        return;
                      }
                      if(redirectTo === 'pricing'){
                       navigate('/pricing', { replace: true });
                       return;
                      }
                      if(redirectTo === 'industrial-trainings'){
                        navigate('/industrial-trainings', { replace: true });
                        return;
                       }
                       navigate('/app/dashboard', { replace: true });
                    }
                  }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {/* Sign in on the internal platform */}
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                  {/* <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Button
                      color="primary"
                      fullWidth
                      startIcon={<FacebookIcon />}
                      onClick={handleSubmit}
                      size="large"
                      variant="contained"
                    >
                      Login with Facebook
                    </Button>
                  </Grid> */}
                  {/* <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Button
                      fullWidth
                      startIcon={<GoogleIcon />}
                      onClick={handleSubmit}
                      size="large"
                      variant="contained"
                    >
                      Login with Google
                    </Button>
                  </Grid> */}
                </Grid>
                <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    with email address
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // type="password"
                  value={values.password}
                  variant="outlined"
                  type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <ShowPassword /> : <HidePassword />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                  <center>or</center>
                <Box my={2}>                
                  <Grid item xs={12}>
                    <Guest redirectToPublic={location.pathname+location.search}/>
                  </Grid>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    variant="h6"
                  >
                    Forgot Password?
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
