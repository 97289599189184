/* eslint-disable */

import React from 'react';
import { toast } from 'react-toastify';
import 'src/style/toastStyles.css'; // Import your custom styles

const NetStatusNotification = (isOnline, message) => {
  if (isOnline) {
    const textLength = message.length;
    const dynamicWidth = textLength < 50 ? '150px' : textLength < 100 ? '200px' : '400px';

        toast.success(message, {
          position: 'top-right', autoClose: true, hideProgressBar: false,
          closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined,
          className: 'custom-toast',
          // style: {
          //   width: dynamicWidth,  // Set dynamic width based on text length
          // },
        });
    }
    else{
        toast.error(message, {
          position: 'top-right', autoClose: true, hideProgressBar: false,
          closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined,
          className: 'custom-toast', 
          // style: {
          //   width: dynamicWidth,  // Set dynamic width based on text length
          // },
        });
    }
}
// Simple App that loads a picture.
const App = (onstart, onload) => {
    onstart();
  
    const h1 = document.createElement("h1");
    h1.innerText = "App";
  
    const img = document.createElement("img");
    img.src = "https://picsum.photos/640/480";
    img.className = "loading";
    img.onload = () => {
      img.className = "";
      onload();
    };
  }
  
let isSlow;
let loaded;
  
const loadApp = () => {
    App(
      () => {
        loaded = false;
        isSlow = setTimeout(() => {
          // Show "Slow Internet Connection." message.
          document.body.className = "slow";
        //   console.log("slow");
          NetStatusNotification(false, "Slow network detected.");
        }, 1500); // Intentionally a low number for testing. Try some different values here, or play with different Internet Throttling setting.
      },
      () => {
        loaded = true;
        clearTimeout(isSlow);
        setTimeout(() => { document.body.className = ""; }, 1000); // Hide message after 1s.
        // console.log("loaded");
    });
  };
const networkStatus = () => {
    window.addEventListener("load", () => {
      // 1st, we set the correct status when the page loads
      navigator.onLine
        ? NetStatusNotification(true, "You are online")
        : NetStatusNotification(false, "You are offline");
  
      // now we listen for network status changes
      window.addEventListener("online", () => {
        // There was not enough time to load the App. Do it again.
        // console.log(loaded);
        if (!loaded) {
            // console.debug("Reconnecting...");
            NetStatusNotification(false, "Reconnecting...");
            loadApp();
        }
        NetStatusNotification(true, "You are online back !!");
      });
  
      window.addEventListener("offline", () => {
        NetStatusNotification(false, "Lost Network Connection !!");
      });
    });
  
    return <div></div>;
  };

export default networkStatus;
