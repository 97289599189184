/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Toolbar as ToolTag,
  Button,
  IconButton,
  AppBar as DrawerBar,
  Typography,
  makeStyles,
  Drawer as AppDrawer,
  Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { isMobile } from 'react-device-detect';
import MeetingApiCall from 'src/utils/MeetingApiCall';
import FilterComponent from './AdvanceReports/FilterComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
const AdvanceReport = (params) => {
  const { meetingId } = params;
  const classes = useStyles();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isdrawerFullscreen, setisdrawerFullscreen] = useState(false);
  const [drawerWidth, setdrawerWidth] = useState(500);
  const [showDrawer, setshowDrawer] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: showDrawer,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleFilterChange = async (filters) => {
    console.log('Selected filters:', filters);
    filters.skip = 0;
    filters.limit = 100;
    const resp = await MeetingApiCall.myTeamCandidates(meetingId, filters);
    if (resp.status === 200) {
      // onClose(); // Close the dialog after submitting the form
      console.log(resp);
    }
    // Perform actions based on selected filters
  };
  const toggleDrawerSize = (siz) => {
    setdrawerWidth(siz);
    setisdrawerFullscreen(!isdrawerFullscreen);
  };
  const list = (anchor) => (
    <div
      role="presentation"
      style={{ width: isMobile ? windowSize.innerWidth : drawerWidth, fontSize: '12px' }}
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerBar
        style={{ width: isMobile ? windowSize.innerWidth : drawerWidth }}
      >
        <ToolTag>
          <Typography variant="h6"> Advance Reports for candidates</Typography>
          <IconButton color="inherit" onClick={() => { toggleDrawerSize(700); }}>
            {!isMobile
              && !isdrawerFullscreen && <FullscreenIcon />}
          </IconButton>

          <IconButton color="inherit" onClick={() => { toggleDrawerSize(500); }}>
            {!isMobile
              && isdrawerFullscreen && <FullscreenExitIcon />}
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            edge="end"
            className={classes.closeButton}
            onClick={() => {
              // setState({right:false});
              setState({ ...state, [anchor]: false });
              // toggleDrawer(anchor, false)
            }}
            // className={clsx(open && classes.hide)}
          >
            <CloseIcon />
          </IconButton>
        </ToolTag>
      </DrawerBar>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <FilterComponent onFilterChange={handleFilterChange} />
      </main>
    </div>
  );
  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button size="small" variant="contained" style={{ fontSize: '12px', whiteSpace: 'nowrap', display: 'inline-block' }} color="primary" onClick={toggleDrawer(anchor, true)}>Advance Report</Button>
          </Box>
          <AppDrawer
            anchor={anchor}
            open={state[anchor]}
                // variant="persistent"
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </AppDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default AdvanceReport;
