import React, { useState } from 'react';
import {
  Fab, Dialog, DialogTitle, DialogContent, Button, TextField
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  share: {
    float: 'right',
    color: 'blue',
    margin: theme.spacing(1)
  },
}));

const ShareFolder = (params) => {
  const { category } = params;
  const [openDialog, setOpenDialog] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(''); // Assuming you generate the link somewhere

  const classes = useStyles();
  const handleShare = () => {
    console.log('sharing', category);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCopyLink = () => {
    // Copy the generated link to the clipboard
    const link = `${process.env.REACT_APP_BASE_URL}/folder/copy/${category.id}`;
    setGeneratedLink(link);
    navigator.clipboard.writeText(link)
      .then(() => {
        console.log('Link copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy link: ', error);
      });
  };
  return (
    <>
      <Fab
        size="small"
        aria-label="share"
        className={classes.share}
        onClick={handleShare}
      >
        <ShareIcon />
      </Fab>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Share Folder to anyone with link</DialogTitle>
        <DialogContent>
          <TextField
            value={generatedLink}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
          />
          <Button onClick={handleCopyLink}>Copy Link</Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareFolder;
