/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import Group from "@material-ui/icons/Group";

import VerifiedUser from "@material-ui/icons/VerifiedUser";
import ListAlt from "@material-ui/icons/ListAlt";

// core components
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import InfoArea from "src/components/InfoArea/InfoArea.js";

import styles from "src/assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Let{"'"}s talk BMS App</h2>
          <h5 className={classes.description}>
            The complete solution of Business Management including task, team, products, content, customers.
            you can store and manage them in a single place here very smoothly.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Unlimited Application"
              description="You can get all the new applicant details with this application form. you can manage and recall the large list of applicants in a few clicks."
              icon={ListAlt}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Verified Users"
              description="Wow, only verified users can be trained and managed here in your business, you have no fear of fake users at all."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Team Manage"
              description="It's very hard to manage your Global business and your team now a days as world is running online. Offline working and management is the old trends now."
              icon={Group}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
