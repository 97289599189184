/* eslint-disable */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    Grid,
    Box
 } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import LiveStatus from './LiveStatus';
import EditApplicantForm from './EditApplicantForm';
import PdfViewer from 'src/utils/PdfViewer';
import Note from './Note';
import { List, ListItem, ListItemText, Paper, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  textStyl: {
    marginBottom: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));
const LogList = ({ logs }) => {
  if (Array.isArray(logs)) {
    // console.log(logs);
  }else{
    return;
  }
  function getDatefromid(id){
    const timestamp = id.toString().substring(0,8);
    const date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return date;
  }
  return (
    <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
      <List>
        {logs.map((item, index) => (
          item._id && (<ListItem key={index}>
            <ListItemText primary={item.log} secondary={moment(getDatefromid(item._id)).format('DD/MM/YYYY hh:mm A')} />
          </ListItem>)
        ))}
      </List>
    </Paper>
  );
};
export default function RecipeReviewCard(params) {
  const {row, tableFont} = params;
  const classes = useStyles();
  const [loglist, setLoglist] = React.useState(row.note);

//   const [expanded, setExpanded] = React.useState(false);
  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const file = row.resume_file;
//   const handleExpandClick = () => {
//     setExpanded(!expanded);
//   };
useEffect(() => {
  setLoglist(row.note);
}, [row]);
  return (
    <>
        <Card className={classes.root}>
        <CardHeader
            // avatar={
            //   <Avatar aria-label="recipe" className={classes.avatar}>
            //     R
            //   </Avatar>
            // }
            action={
              <IconButton aria-label="settings">
                <EditApplicantForm  row={row} tableFont={tableFont} />
              </IconButton>
            }
            title={<div><h2>Name: {capitalizeFirst(row.name)}</h2></div>}
            subheader={(<div><a href={`tel:+91-${row.phone}`}>+91-{row.phone}</a> </div>)}
        />
        {/* <CardMedia
            className={classes.media}
            image="/static/images/cards/paella.jpg"
            title="Paella dish"
        /> */}
        <CardContent>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>Email:</b>  {row.email}
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>Appointment:</b>  {row.conn_time? moment(row.conn_time).format('MMM Do, YYYY hh:mm A'):'NA'}
                    {row.conn_time?
                     moment(row.conn_time) > moment()?
                      <span style={{margin:'5px', color: 'green'}}>Upcoming</span>:
                      <span style={{margin:'5px', color: 'red'}}>Expired</span>:
                      ''}
                </Typography>
            {(!row.hasOwnProperty('app_mode') || row.app_mode==='app_form' || row.app_mode==='custom_contact') && 
            (<>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>D.O.B.:</b>  {row.dob ? moment(row.dob).format('DD/MM/YYYY'): 'NA'}
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>Address:</b>  {row.address}
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>City:</b>  {row.city}
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>State:</b>  {row.state}
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>Country:</b>  {row.country}
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>Zip:</b> ({row.zip})
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>Qualification:</b> {row.qualification}
                </Typography>
                <Typography className={classes.textStyl} variant="body2" color="textSecondary" component="p">
                    <b>Currently Doing:</b> {row.current_status}
                </Typography>
            </>
            )}
                <Grid container>
                    <Grid item className={classes.textStyl} xs={3} sm={3}>
                        <b>Live Status:</b>
                    </Grid>
                    <Grid item className={classes.textStyl} xs={9} sm={9}>
                        <LiveStatus row={row} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className={classes.textStyl} xs={3} sm={3}>
                        <b>Custom Notes:</b>
                    </Grid>
                    <Grid item className={classes.textStyl} xs={9} sm={9}>
                        <Note {...row}/>
                    </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <b>Old Notes:</b>
                    <LogList logs={loglist} />
                  </Grid>
                </Grid>
        </CardContent>
        </Card>
        <div>
        {row.app_mode==='resume' && 
            (
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Box
                        width="100%"
                        height="600px"
                        display="block"
                        justifyContent="center"
                        mb={12}
                    >
                        <PdfViewer file={file}/>
                    </Box>
                </Grid>
            </Grid>
            )}
        </div>
    </>
  );
}
