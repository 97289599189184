/* eslint-disable */
import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from 'react-router-dom';

// @material-ui/icons

// core components
import Header from "src/components/Header/Header.js";
import Footer from "src/components/Footer/Footer.js";
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import Button from "src/components/CustomButtons/Button.js";
import HeaderLinks from "src/components/Header/HeaderLinks.js";
import Parallax from "src/components/Parallax/Parallax.js";
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';

import styles from "src/assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import landingbg from "src/assets/img/landing-bg.jpg";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [userLoggedin, SetUserLoggedin] = useState(inLocalStorageJwt.getLoggedinUserData());

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="BMS GLOBAL"
        rightLinks={ <HeaderLinks /> }
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={ landingbg }>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title} style={{ 'fontSize': '4em' }}>We Manage, You Grow.</h1>
              <h4 style={{ 'fontSize': '1.125rem', 'fontWeight': '300', 'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif'}}>
                Every entrepreneur needs a personal assistant, but not every body can afford it, So we are here to manage all your stuff. You are welcome.
              </h4>
              <br />
              {userLoggedin.id && <Button
                color="danger"
                size="lg"
                to="app/dashboard"
                rel="noopener noreferrer"
                component={RouterLink}
              >
                <i className="fas fa-play" />
                View Dashboard
              </Button>}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <TeamSection />
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
