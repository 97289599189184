/* eslint-disable */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import moment from 'moment';
import {
  Container,
  Avatar,
  Button,
  // Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { Link as RouterLink , useNavigate, useParams, useSearchParams} from 'react-router-dom';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import BusinessIcon from '@material-ui/icons/Business';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import Breadcrumbs from '../BreadCrumbs';
import Participants from './Participants';
import MeetingApiCall from 'src/utils/MeetingApiCall';
import AddDialog from './AddDialog';
import AdvanceReport from './AdvanceReport';
import ParticipantFilter from './ParticipantsFilter';
import ParticipantsChart from './ParticipantsChart';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spacer: {
    marginBottom:  '5px'
  },
  OfficeBtn: {
    margin: '5px',
    backgroundColor: 'aqua'
  },
  OnlineBtn: {
    margin: '5px',
    backgroundColor: 'gold'
  }
}));

const Offices = () => {
  const classes = useStyles();
  const { meetingId } = useParams();
  const [meetingData, setMeetingData] = useState({});
  const [dropDownlist, setDropDownlist] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [partRefresh, setPartRefresh] = useState(false);
  const defaultDate = new Date();
  const [filters, setFilters] = useState({
    date: searchParams.get('date')? searchParams.get('date') : defaultDate,
    name: searchParams.get('name')? searchParams.get('name') : '',
    phone: searchParams.get('phone')?searchParams.get('phone') : '',
    senior: searchParams.get('senior')?searchParams.get('senior'): '',
    participantType: searchParams.get('participantType')?searchParams.get('participantType'): 'all',
  });

  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [itemsArray, setItemsArray] = React.useState([{page: 'Home', href: '/app/dashboard'}]); 
  const navigate = useNavigate();

  // const itemsArray = [
  //   {page: 'Home', href: '/app/dashboard'},
  //   {page: 'Super-5', href: '/app/group-details/66118dccf137e33dbd3ee93b'},
  //   {page: 'Sec-4, office', href: '/app/office-details/66118dccf137e33dbd3ee93b'}
  // ];
  const getDropDownlist = async (ofcId) => {
    try {
      const resp = await MeetingApiCall.getDropDownlist(ofcId);
      if (resp.status === 200 && resp.users) {
        console.log(resp.users);
        setDropDownlist(resp.users);
        // console.log(resp.mygroups);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    }
  };
  const onFilter = async (filtersParam) => {
    const { dateFilter, participantType } = filtersParam;
    // setSearchParams({date: dateFilter, participantType: participantType, ...searchParams });
    console.log(dateFilter, participantType);
    setFilters({ ...filters, date: dateFilter, participantType });
    setPartRefresh(true);
    console.log("filter called");
  };
  React.useEffect(() =>{
    // console.log(filterData);
    setSearchParams({...filters});
    // setSearchParams({...searchParams,...filterData});
  },[filters])
  const meetingDetails = async () => {
    try {
      const resp = await MeetingApiCall.meetingDetails(meetingId);
      if (resp.status === 200 && resp.meeting) {
        setMeetingData(resp.meeting);
        const meetingDetails = resp.meeting;
        setItemsArray([
          ...itemsArray,
          {page: meetingDetails && meetingDetails.officeId.groupId.gname, href: `/app/group-details/${meetingDetails && meetingDetails.officeId.groupId.id}`},
          {page: meetingDetails && meetingDetails.officeId.name, href: `/app/office-details/${meetingDetails && meetingDetails.officeId.id}`},
          {page: meetingDetails && 'Daily Working', href: `/app/office-details/${meetingDetails && meetingDetails.officeId.id}/daily-working`},
          // {page: meetingDetails && meetingDetails.title, href: `/app/meeting-details/${meetingId}`}
        ]);
        // console.log(resp.mygroups);
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    }
  };
  const refreshList = () => {
    setPartRefresh(true);
  }
  React.useEffect(() => {
    meetingDetails();
  }, []);
  React.useEffect(() => {
    meetingData.officeId && getDropDownlist(meetingData.officeId.id);
  }, [meetingData]);
  return (
    <Page
      className={classes.root}
      title="Meeting Details"
    >
      <Container maxWidth={false}>
        <Breadcrumbs itemsArray = {itemsArray} className={classes.spacer} lastPage = {{page:`${meetingData.title} Details`}}/>
        <Grid
          container
          spacing={3}
        >
          <>
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <>
                      <Grid item key={uuid()}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="h3"
                        >
                          {meetingData && meetingData.title}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          {meetingData && meetingData.slot}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                        >
                          {`Candidates ${moment(filters.date).format('DD MMM, YYYY')}`}
                        </Typography>
                      </Grid>
                      <Grid
                        item 
                        lg={2}
                        md={2}
                        xl={1}
                        xs={5}
                      >
                        <AdvanceReport meetingId={meetingId} slot={meetingData.slot} title={meetingData.title} refreshList={refreshList} />
                        <AddDialog dropDownlist={dropDownlist} meetingId={meetingId} slot={meetingData.slot} title={meetingData.title} refreshList={refreshList} />
                      </Grid>
                      <Grid
                        item 
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                      >
                        <ParticipantFilter onFilter={onFilter} filters={filters} />
                      </Grid>
                      <Grid
                        item 
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                      >
                        <Participants meetingId={meetingId} filters={filters} partRefresh={partRefresh} setPartRefresh={setPartRefresh} />
                      </Grid>
                    </>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        </Grid>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item 
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <ParticipantsChart userId={getLoggedinUserData.id} meetingId={meetingId}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Offices;
