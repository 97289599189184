/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import {
  Box,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import CustomInput from "src/components/CustomInput/CustomInput.js";
import Button from "src/components/CustomButtons/Button.js";
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from "src/assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  textarea: {
    resize: 'both'
  }
}));
export default function WorkSection() {
  const classes = useStyles();
  const classes2 = useStyles2();

  return (
    <div className={classes.section} id="contactus">
      <GridContainer justifyContent="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contact us</h2>
          {/* <h4 className={classes.description}>
            Divide details about your product or agency work into parts. Write a
            few lines about each one and contact us about any further
            collaboration. We will responde get back to you in a couple of
            hours.
          </h4> */}
          <Formik
            initialValues={{
              name: '',
              phone: '',
              email: '',
              message: ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(100).required('Please enter your name.'),
              phone: Yup.string().max(11).required('Please enter your phone.'),
              email: Yup.string().email('Must be a valid email').max(255).required('Please enter your email.'),
              message: Yup.string().max(355).required('Please enter your message.')
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const postData = {
                name: values.name,
                phone: values.phone,
                email: values.email,
                message: values.message,
              };
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                }
              };
              axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/contactus`, postData, options)
                .then((res) => {
                  const respData = res.data.data;
                  // console.log(res);
                    toast.success(`${res.data.message}`, {
                      position: 'top-right',
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    if (res.data.status === true) {
                      resetForm({values: ''});
                      // navigate('/app/dashboard', { replace: true });
                    }
                })
                .catch((error) => {
                  // what now?
                  // console.log(error.response.data.message);
                  setSubmitting(false);
                  // Error
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(`${error.response.data.message}`, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest
                    // in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                  }
                });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Your Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="name"
                      value={values.name}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label="Your Phone"
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="phone"
                      value={values.phone}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      // variant="filled"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      error={Boolean(touched.message && errors.message)}
                      helperText={touched.message && errors.message}
                      label="Your Message"
                      id="message"
                      name="message"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      multiline
                      rows="5"
                      inputProps={{
                        className: classes2.textarea
                      }}
                      value={values.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button color="primary" disabled={isSubmitting}  type="submit">Send Message</Button>
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Formik>
        </GridItem>
      </GridContainer>
    </div>
  );
}
