import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Grid,
  makeStyles
} from '@material-ui/core';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import * as Yup from 'yup';
import CouponApiCall from 'src/utils/CouponApiCall';

const useStyles = makeStyles(() => ({
  root: {},
  submitBtn: {
    float: 'right'
  },
  errorText: {
    color: 'red',
  },
}));
const validationSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
  discount: Yup.number()
    .required('Discount is required')
    .positive('Discount must be positive')
    .max(100, 'Discount cannot be more than 100')
    .typeError('Discount must be a number'),
  expiryDate: Yup.date().required('Expiry Date is required'),
  usageLimit: Yup.number()
    .integer('Usage Limit must be an integer')
    .min(0, 'Usage Limit must be greater than or equal to 0')
    .typeError('Usage Limit must be a number'),
});

const initialValues = {
  code: '',
  discount: 0,
  expiryDate: '',
  usageLimit: null,
};

const CouponForm = ({ onCouponCreated, handleClose }) => {
  const classes = useStyles();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    // console.log(values);
    try {
      const resp = await CouponApiCall.createCoupon(values);
      console.log('coupon created', resp);
      if (resp.status === 200 && resp.coupon) {
        onCouponCreated(resp.coupon);
        resetForm();
        handleClose();
      }
    } catch (error) {
      console.error('Error creating coupon:', error);
      // Handle error
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <h2>Create Coupon</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="code"
                  label="Code"
                />
                <ErrorMessage name="code" component="div" className={classes.errorText} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="discount"
                  label="Discount"
                  type="number"
                />
                <ErrorMessage name="discount" component="div" className={classes.errorText} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="expiryDate"
                  label="Expiry Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorMessage name="expiryDate" component="div" className={classes.errorText} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  as={TextField}
                  fullWidth
                  name="usageLimit"
                  label="Usage Limit"
                  type="number"
                />
                <ErrorMessage name="usageLimit" component="div" className={classes.errorText} />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.submitBtn}
                >
                  Create Coupon
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CouponForm.propTypes = {
  onCouponCreated: PropTypes.func,
  handleClose: PropTypes.func,
};

export default CouponForm;
