/* eslint-disable */
import { useState } from "react";
import { TableFlowImporter } from "@tableflow/react";

function MyComponent() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <p>Coming soon</p>
    </>
  );
}

export default MyComponent;