import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import countries from 'src/views/countries';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CheckSquare
} from 'react-feather';
// import Typography from '@material-table/core';
// import { Link as RouterLink, useLocation } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  Table, Thead, Tbody, Tr, Th, Td
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import UserContext from 'src/contexts/UserContext';
// import NavbarContext from 'src/contexts/NavbarContext';
// import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import { Formik } from 'formik';
import OTPVerification from './OTPVerification'; // Import the OTPVerification component

// const states = [
//   {
//     value: 'alabama',
//     label: 'Alabama'
//   },
//   {
//     value: 'new-york',
//     label: 'New York'
//   },
//   {
//     value: 'san-francisco',
//     label: 'San Francisco'
//   }
// ];
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableRow: {
    border: '1px solid black'
  },
  respHeader: {
    margin: '10px',
    padding: '10px',
    textAlign: 'center',
    border: '1px solid black'
  }
}));
const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [user, SetUser] = useState(null);
  const [tags, setTags] = useState([]);
  const [editEmail, SetEditEmail] = useState(true);
  const query = useQuery();
  const callBack = query.get('callback');
  const transactionStatus = query.get('status');
  const messageResp = query.get('message');
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading
  const userContext = useContext(UserContext);
  // const navBarContext = useContext(NavbarContext);
  const formikRef = useRef();
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  // console.log(getLoggedinUserData);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  useEffect(() => {
    // console.log(callBack);
    const getUser = async () => {
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${getLoggedinUserData.id}`, options)
        .then((res) => {
          // console.log(res);
          SetUser(res.data.user);
          if (res.data.user.email !== '') {
            SetEditEmail(false);
          }
          // console.log("user", user);
        });
    };
    const refreshToken = async () => {
      await ApiCall.getRefreshToken();
      const userData = await inLocalStorageJwt.getLoggedinUserData();
      userContext.userData = userData;
      // navBarContext.updateNav(userData);
      // console.log(userContext.userData);
    };
    if (callBack === 'true') {
      if (transactionStatus === 'TXN_SUCCESS') {
        toast.success(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (transactionStatus === 'TXN_FAILURE') {
        toast.error(`${messageResp}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      refreshToken();
    }
    getUser();
  }, []);

  // useEffect(() => {
  //   console.log("user2", user);
  // }, [user]);
  // const [country, setCountry] = useState("");
  // const [region, setRegion] = useState("");

  // const handleChangeCountry = (event) => {
  //   setCountry(event.target.value);
  // };

  // const handleChangeRegion = (event) => {
  //   setRegion(event.target.value);
  // };

  // const handleVerifyClick = () => {
  //   setOpenDialog(true); // Open the OTP verification dialog
  // };
  const handleCloseDialog = () => {
    // console.log('clicked close', openDialog);
    setOpenDialog(false); // Close the OTP verification dialog
  };
  const handleCountryClick = (event) => {
    console.log('hello', event.target.name);
  };

  const handleClickEditEmail = async () => {
    const emailValue = formikRef.current.values.email;
    // console.log('open clicked', openDialog);
    SetEditEmail(true);
    // console.log(emailValue);
    if (emailValue && editEmail) {
      setLoading(true);
      const resp = await ApiCall.requestEmailChangeOtp(emailValue);
      setLoading(false);
      if (resp.status === 200) {
        setOpenDialog(true); // Open the OTP verification dialog
      }
    }
  };
  // const phoneRegExp = /^[0-9]{2}[0-9]{8}$/;
  const phoneRegExp = /^[0-9]{10}$/;
  const SuperRoles = ['paid_user', 'admin', 'bms_promotor'];
  const learnerRoles = ['learner'];

  // console.log(user);
  // const zipRegExp = /^[1-9]{1}[0-9]{2}\\s{0, 1}[0-9]{3}$/;

  useEffect(() => {
    if (user && formikRef.current) {
      formikRef.current.setFieldValue('email', user.email, false);
      if (user.address.length > 0) {
        const userAddress = user.address[0];
        // console.log("length",user.address[0])

        formikRef.current.setFieldValue('country', userAddress.country ? userAddress.country : '', false);
        formikRef.current.setFieldValue('state', userAddress.state ? userAddress.state : '', false);
        formikRef.current.setFieldValue('line1', userAddress.line1 ? userAddress.line1 : '', false);
        formikRef.current.setFieldValue('line2', userAddress.line2 ? userAddress.line2 : '', false);
        formikRef.current.setFieldValue('zip', userAddress.zip ? userAddress.zip : '', false);
        formikRef.current.setFieldValue('city', userAddress.city ? userAddress.city : '', false);
      }

      if (user.company.length > 0) {
        const userCompany = user.company[0];
        formikRef.current.setFieldValue('companyName', userCompany.name ? userCompany.name : '', false);
        formikRef.current.setFieldValue('industry', userCompany.industry ? userCompany.industry : '', false);
        formikRef.current.setFieldValue('yourLevel', userCompany.your_level ? userCompany.your_level : '', false);
        formikRef.current.setFieldValue('yourId', userCompany.your_id ? userCompany.your_id : '', false);
        formikRef.current.setFieldValue('joiningDate', userCompany.joining_date ? moment(userCompany.joining_date).format('yyyy-MM-DD') : '', false);
        formikRef.current.setFieldValue('sponsorId', userCompany.sponsor_id ? userCompany.sponsor_id : '', false);
      }
      if (user.workapplication.length > 0) {
        const userWApplication = user.workapplication[0];
        formikRef.current.setFieldValue('topTitle', userWApplication.topTitle ? userWApplication.topTitle : '', false);
        formikRef.current.setFieldValue('largeTitle', userWApplication.largeTitle ? userWApplication.largeTitle : '', false);
        formikRef.current.setFieldValue('description', userWApplication.description ? userWApplication.description : '', false);
        formikRef.current.setFieldValue('formHeader', userWApplication.formHeader ? userWApplication.formHeader : '', false);
        formikRef.current.setFieldValue('form_purpose', userWApplication.formPurpose ? userWApplication.formPurpose : '', false);
        setTags(userWApplication.formPurpose);
      }
      formikRef.current.setFieldValue('phone', user.phone, '');
      formikRef.current.setFieldValue('firstName', user.fname, '');
      formikRef.current.setFieldValue('lastName', user.lname, '');
      formikRef.current.setFieldValue('bmsSponsor', user.bms_sponsor, '');
    }
  }, [user]);

  useEffect(() => {
    formikRef.current.setFieldValue('form_purpose', [...tags], false);
  }, [tags]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          email: '',
          phone: '',
          firstName: '',
          lastName: '',
          state: '',
          country: '',
          city: '',
          zip: '',
          line1: '',
          line2: '',
          companyName: '',
          industry: '',
          yourLevel: '',
          yourId: '',
          joiningDate: '',
          sponsorId: '',
          bmsSponsor: '',
          topTitle: '',
          largeTitle: '',
          description: '',
          formHeader: '',
          form_purpose: ''
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').required('Email is required'),
          country: Yup.string().required('Country is required'),
          state: Yup.string().required('Region is required'),
          phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
          firstName: Yup.string().min(3).required('First Name is required'),
          lastName: Yup.string().required('Last Name is required'),
          line1: Yup.string().required('Address is required'),
          line2: Yup.string().min(3),
          zip: Yup.string().min(6, 'zip must have 6 digits.').max(6, 'zip must have 6 digits.').required('Pincode is required'),
          city: Yup.string().min(3).required('City is required'),
          companyName: Yup.string().min(3, 'Company name should be atleast 3 characters long.'),
          industry: Yup.string().min(3, 'Industry should be atleast 3 characters long.'),
          yourLevel: Yup.string().min(3, 'Your level should be atleast 3 characters long.'),
          yourId: Yup.string().min(3, 'Your Id should be atleast 3 characters long.'),
          joiningDate: Yup.string().min(3, 'Joining Date should be atleast 3 characters long.'),
          sponsorId: Yup.string().min(3, 'Sponsor Id should be atleast 3 characters long.'),
          bmsSponsor: Yup.string().email('Must be a valid email.'),
          topTitle: Yup.string().max(255, 'Top Title should be less than 255 characters.'),
          largeTitle: Yup.string().max(255, 'Large Title should be less than 255 characters.'),
          description: Yup.string().max(1000, 'Description should be less than 1000 characters.'),
          formHeader: Yup.string().max(255, 'Form header should be less than 255 characters.')
        })}
        onSubmit={(values, { setSubmitting }) => {
          // console.log(AuthToken);
          const postData = {
            fname: values.firstName,
            lname: values.lastName,
            email: values.email,
            phone: values.phone,
            line1: values.line1,
            line2: values.line2,
            zip: values.zip,
            country: values.country,
            state: values.state,
            city: values.city,
            company_name: values.companyName,
            industry: values.industry,
            your_level: values.yourLevel,
            your_id: values.yourId,
            joining_date: values.joiningDate,
            sponsor_id: values.sponsorId,
            bms_sponsor: values.bmsSponsor,
            topTitle: values.topTitle,
            largeTitle: values.largeTitle,
            description: values.description,
            formHeader: values.formHeader,
            form_purpose: values.form_purpose
          };
          // const options = {
          //   headers: {
          //     'Content-Type': 'application/json',
          //     Authorization: `Bearer ${AuthToken}`,
          //   }
          // };
          // console.log('postData', postData);
          axios.patch(`${process.env.REACT_APP_API_BASE_URL}/users/${getLoggedinUserData.id}`, postData, options)
            .then((res) => {
              // console.log(res.data.user);
              if (res.data.user.id) {
                inLocalStorageJwt.setLoggedinUserData(res.data.user);
                inLocalStorageJwt.setUserPermissions(res.data.userPermissions);
              }
              toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setSubmitting(false);
            })
            .catch((error) => {
              // what now?
              // console.log(error.response.data.message);
              setSubmitting(false);
              // Error
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                toast.error(`${error.response.data.message}`, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                // in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error);
              }
            });
          // navigate('/app/dashboard', { replace: true });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Card>
                <CardHeader
                  subheader="The information can be edited"
                  title={(
                    <>
                      <span>
                        {`Account Type: ${user && (SuperRoles.indexOf(user.role) > -1 || learnerRoles.indexOf(user.role) > -1) ? 'Licensed' : 'Basic'}`}
                        <CheckSquare style={{ bottom: '0px' }} />
                      </span>
                      <span>
                        {user && (user.role === 'user' || user.role === 'guest')
                         && (
                         <>
                           <Typography>Need More?</Typography>
                           <Button
                             color="primary"
                             component={RouterLink}
                             to="/pricing"
                             variant="contained"
                           >
                             See PRO version
                           </Button>
                         </>
                         )}
                      </span>
                    </>
                    )}
                />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    {
                    user && user.subscriptions && user.subscriptions.length > 0
                    && (
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                        <Table
                          style={{ border: '1px solid black' }}
                          md={12}
                          xs={12}
                        >
                          <Thead>
                            <Tr className={classes.tableRow} style={{ border: '1px solid black' }}>
                              <Th className={classes.respHeader}>Plan/Price</Th>
                              <Th className={classes.respHeader}>Start Date</Th>
                              <Th className={classes.respHeader}>End Date</Th>
                              <Th className={classes.respHeader}>Phone Noti</Th>
                              <Th className={classes.respHeader}>Email Noti</Th>
                              <Th className={classes.respHeader}>Application Form</Th>
                              <Th className={classes.respHeader}>Tasks</Th>
                              <Th className={classes.respHeader}>Customers</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr className={classes.tableRow} style={{ border: '1px solid black' }}>
                              <Td
                                className={classes.respHeader}
                              >
                                {user.subscriptions[0].plan.name}
                                (
                                {user.subscriptions[0].plan.price}
                                {' '}
                                ₹
                                )
                              </Td>
                              <Td
                                className={classes.respHeader}
                              >
                                {moment(user.subscriptions[0].startDate).format('MMM DD, YYYY, hh:mm A')}
                              </Td>
                              <Td
                                className={classes.respHeader}
                              >
                                {moment(user.subscriptions[0].endDate).format('MMM DD, YYYY, hh:mm A')}
                              </Td>
                              <Td
                                className={classes.respHeader}
                              >
                                {user.subscriptions[0].usage[0].phoneNoti}
                              </Td>
                              <Td
                                className={classes.respHeader}
                              >
                                {user.subscriptions[0].usage[0].emailNoti}
                              </Td>
                              <Td
                                className={classes.respHeader}
                              >
                                {user.subscriptions[0].usage[0].appliForm}
                              </Td>
                              <Td
                                className={classes.respHeader}
                              >
                                {user.subscriptions[0].usage[0].task}
                              </Td>
                              <Td
                                className={classes.respHeader}
                              >
                                {user.subscriptions[0].usage[0].customers}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Grid>
                    )
                  }
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label="First name"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        fullWidth
                        label="Last name"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                        label="Email Address"
                        name="email"
                        onChange={handleChange}
                        InputProps={{ // <-- This is where the toggle button is added.
                          readOnly: !editEmail,
                          disabled: !editEmail,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="edit email"
                                onClick={handleClickEditEmail}
                              >
                                { (!values.email || !editEmail) && <EditIcon /> }
                                { (values.email && !loading && editEmail) ? 'verify' : '' }
                                { loading && <CircularProgress size={24} /> }
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        onBlur={handleBlur}
                        required
                        value={values.email}
                        variant="outlined"
                      />
                      <OTPVerification open={openDialog} onClose={handleCloseDialog} />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        inputProps={{
                          readOnly: Boolean(true),
                          disabled: Boolean(true),
                        }}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                        fullWidth
                        label="Phone Number"
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        value={values.phone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                        fullWidth
                        label="Select Country"
                        name="country"
                        onClick={handleCountryClick}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.country}
                        variant="outlined"
                      >
                        {/* <option value="" key="">Select Country</option> */}
                        {countries.map((country) => (
                          <option
                            key={country.country}
                            value={country.country}
                          >
                            {country.country}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                        fullWidth
                        label="Select State"
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.state}
                        variant="outlined"
                        disabled={!values.country}
                      >
                        {/* {states.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))} */}
                        {values.country
                          ? countries
                            .find(({ country }) => country === values.country)
                            .states.map((region) => (
                              <option value={region} key={region}>
                                {region}
                              </option>
                            ))
                          : []}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.line1 && errors.line1)}
                        helperText={touched.line1 && errors.line1}
                        fullWidth
                        label="Address-1"
                        placeholder="HNO/Floor Num. etc."
                        name="line1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.line1}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.line2 && errors.line2)}
                        helperText={touched.line2 && errors.line2}
                        fullWidth
                        label="Address-2"
                        placeholder="Locality, Colony etc."
                        name="line2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.line2}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.zip && errors.zip)}
                        helperText={touched.zip && errors.zip}
                        fullWidth
                        label="Pin Code"
                        name="zip"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        value={values.zip}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        fullWidth
                        label="City"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.city}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardHeader
                  subheader="Enter your Company details"
                  title="Profession"
                />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.companyName && errors.companyName)}
                        fullWidth
                        helperText={touched.companyName && errors.companyName}
                        label="Company name"
                        name="companyName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.companyName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.industry && errors.industry)}
                        fullWidth
                        helperText={touched.industry && errors.industry}
                        label="Industry"
                        name="industry"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.industry}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.yourLevel && errors.yourLevel)}
                        fullWidth
                        helperText={touched.yourLevel && errors.yourLevel}
                        label="Enter your level"
                        name="yourLevel"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.yourLevel}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.yourId && errors.yourId)}
                        fullWidth
                        helperText={touched.yourId && errors.yourId}
                        label="Enter Your Company Id"
                        name="yourId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.yourId}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.joiningDate && errors.joiningDate)}
                        fullWidth
                        helperText={touched.joiningDate && errors.joiningDate}
                        label="Joining Date"
                        name="joiningDate"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.joiningDate}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.sponsorId && errors.sponsorId)}
                        fullWidth
                        helperText={touched.sponsorId && errors.sponsorId}
                        label="Sponsor Id"
                        name="sponsorId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sponsorId}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardHeader
                  subheader="referenced by"
                  title="BMS Reference Details"
                />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        inputProps={{
                          readOnly: user && user.role === 'user' && user.is_sponsored ? Boolean(true) : Boolean(false),
                          disabled: user && user.role === 'user' && user.is_sponsored ? Boolean(true) : Boolean(false),
                        }}
                        error={Boolean(touched.bmsSponsor && errors.bmsSponsor)}
                        fullWidth
                        helperText={touched.bmsSponsor && errors.bmsSponsor}
                        label="BMS Sponsor"
                        name="bmsSponsor"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bmsSponsor}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                {user && SuperRoles.indexOf(user.role) > -1
                && (
                  <>
                    <CardHeader
                      subheader="Enter your Work Application Form setting"
                      title="BUSINESS TOOL"
                    />
                    <CardContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.topTitle && errors.topTitle)}
                            fullWidth
                            helperText={touched.topTitle && errors.topTitle}
                            label="Top Title"
                            name="topTitle"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.topTitle}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.largeTitle && errors.largeTitle)}
                            fullWidth
                            helperText={touched.largeTitle && errors.largeTitle}
                            label="Large Title"
                            name="largeTitle"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.largeTitle}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.formHeader && errors.formHeader)}
                            fullWidth
                            helperText={touched.formHeader && errors.formHeader}
                            label="Form Header"
                            name="formHeader"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.formHeader}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            error={Boolean(touched.description && errors.description)}
                            fullWidth
                            helperText={touched.description && errors.description}
                            label="Form Description"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <Autocomplete
                            style={{ margin: '10px 0' }}
                            multiple
                            id="tags-outlined"
                            options={tags}
                            defaultValue={[...tags]}
                            freeSolo
                            autoSelect
                            onChange={(e) => setTags([...tags, e.target.value])}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tags"
                                placeholder="Tags"
                                value={tags}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </>
                )}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <Grid
                    item
                    style={{ margin: '3px' }}
                  >
                    <Button
                      // color="secondary"
                      // style={{ backgroundColor: '#21b6ae' }}
                      variant="contained"
                      onClick={() => { navigate('/app/dashboard'); }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid
                    item
                    style={{ margin: '3px' }}
                  >
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Save details
                    </Button>
                  </Grid>
                </Box>
              </Card>
            </form>
          );
        }}
      </Formik>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h2">Training Assigned</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
