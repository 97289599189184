/* eslint-disable max-len */
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

const FilterComponent = (params) => {
  const { onFilterChange } = params;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterOption, setFilterOption] = useState('myTeam');
  const [endDateKey, setEndDateKey] = useState(Date.now()); // Unique key for forcing re-render

  const handleStartDateChange = (event) => {
    const selectedStartDate = new Date(event.target.value);
    const selectedEndDate = new Date(endDate);
    setStartDate(selectedStartDate.toISOString().split('T')[0]);
    console.log(selectedStartDate, selectedEndDate);
    if (endDate && (selectedStartDate > selectedEndDate)) {
      // Adjust end date if start date is changed after selecting end date
      console.log(selectedStartDate, endDate);
      setEndDate(null);
      setEndDateKey(Date.now());
    }
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = new Date(event.target.value);
    setEndDate(selectedEndDate.toISOString().split('T')[0]);
  };

  const handleFilterOptionChange = (event) => {
    setFilterOption(event.target.value);
  };

  const handleApplyFilters = () => {
    // Pass filter values to parent component
    onFilterChange({ startDate, endDate, filterOption });
  };

  return (
    <Box style={{ padding: '10px' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate || ''}
            onChange={handleStartDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: { fontSize: 12 }
            }}
            fullWidth
            style={{ fontSize: '12px' }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            key={endDateKey} // Use key prop to force re-render
            label="End Date"
            type="date"
            value={endDate || ''}
            onChange={handleEndDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: startDate || undefined, // Disable selection of dates before the start date
              style: { fontSize: 12 }
            }}
            fullWidth
            style={{ fontSize: '12px !important' }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            select
            label="Filter Option"
            value={filterOption}
            onChange={handleFilterOptionChange}
            inputProps={{
              style: { fontSize: 12 }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  style: {
                    fontSize: '12px', // Adjust the font size according to your preference
                  },
                },
              },
              SelectDisplayProps: {
                style: {
                  fontSize: '12px', // Adjust the font size of the selected text
                },
              },
            }}
            style={{ fontSize: '12px' }}
            fullWidth
          >
            <MenuItem value="myCandidates" style={{ fontSize: '12px' }}>My Candidates</MenuItem>
            <MenuItem value="myTeam" style={{ fontSize: '12px' }}>My Team</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            onClick={handleApplyFilters}
            variant="contained"
            color="primary"
            disabled={!startDate || !endDate} // Disable button if either start date or end date is not selected
            fullWidth
            size="small"
            style={{ fontSize: '12px', whiteSpace: 'nowrap', display: 'inline-block' }}
          >
            Apply Filters
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

// FilterComponent.propTypes = {
//   onFilterChange: PropTypes.func.isRequired,
// };

export default FilterComponent;
