import axios from 'axios';
import { toast } from 'react-toastify';
import inLocalStorageJwt from './inLocalStorageJwt';

const convertTimeTOAMPM = (timestamp) => {
  // const timestamp = "2024-04-22T09:30:31.895Z";
  const date = new Date(timestamp);
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    hour12: true // Display time in 12-hour format (AM/PM)
  };
  const formattedTime = date.toLocaleString('en-US', options);
  return formattedTime;
};

const createMeeting = async (officeId, values) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`
    }
  };
  values.passcode = '12345ghhh';
  values.slot = convertTimeTOAMPM(values.slot);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/meetings/create/${officeId}`,
      values,
      options
    );
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    return {
      status: 200,
      meeting: response.data.meeting,
      message: response.data.message
    };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    return {
      status: 400,
      meeting: '',
      message: error.response.data.message
    };
  }
};
const meetingList = async (officeId, fields) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const {
    skip, limit, title, slot, date, type, mode, frequency
  } = fields;
  let searchQuery = `?skip=${skip}&limit=${limit}`;
  if (title) {
    searchQuery += `&title=${title}`;
  }
  if (slot) {
    searchQuery += `&slot=${slot}`;
  }
  if (date) {
    searchQuery += `&date=${date}`;
  }
  if (type) {
    searchQuery += `&type=${type}`;
  }
  if (mode) {
    searchQuery += `&mode=${mode}`;
  }
  if (frequency) {
    searchQuery += `&frequency=${frequency}`;
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/meetings/list/${officeId}${searchQuery}`, options);
    return {
      status: 200,
      meetings: response.data.result.meetings,
      totalMeetings: response.data.result.totalMeetings,
      message: response.data.message,
    };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return {
      status: 400, meetings: '', totalMeetings: 0, message: error.response.data.message
    };
  }
};
const meetingDetails = async (meetingId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/meetings/view/${meetingId}`, options);
    return { status: 200, meeting: response.data.meeting, message: response.data.message };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { status: 400, meeting: '', message: error.response.data.message };
  }
};

const getDropDownlist = async (officeId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/meetings/dropdown-users-list/${officeId}`, options);
    return {
      status: 200,
      users: response.data.result,
      message: response.data.message,
    };
  } catch (error) {
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return {
      status: 400, users: '', totalMeetings: 0, message: error.response.data.message
    };
  }
};

const createParticipant = async (meetingId, values) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`
    }
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/participants/create/${meetingId}`,
      values,
      options
    );
    toast.success(`${response.data.message}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    return {
      status: 200,
      participant: response.data.participant,
      message: response.data.message
    };
  } catch (error) {
    // console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    return {
      status: 400,
      participant: '',
      message: error.response.data.message
    };
  }
};

const participantList = async (meetingId, fields) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const {
    skip, limit, name, senior, date, phone, participantType
  } = fields;
  let searchQuery = `?skip=${skip}&limit=${limit}`;
  if (name) {
    searchQuery += `&name=${name}`;
  }
  if (senior) {
    searchQuery += `&senior=${senior}`;
  }
  if (date) {
    searchQuery += `&date=${date}`;
  }
  if (phone) {
    searchQuery += `&phone=${phone}`;
  }
  if (participantType) {
    searchQuery += `&participantType=${participantType}`;
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/participants/list/${meetingId}${searchQuery}`, options);
    return {
      status: 200,
      participants: response.data.result.participants,
      totalParticipants: response.data.result.totalparticipants,
      message: response.data.message,
    };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return {
      status: 400, participants: '', totalParticipants: 0, message: error.response.data.message
    };
  }
};

const participantsTrend = async (meetingId, days) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/participants/trends/${meetingId}/${days}`, options);
    return {
      status: 200,
      participants: response.data.result,
      message: response.data.message,
    };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return {
      status: 400, participants: '', message: error.response.data.message
    };
  }
};

const myTeamCandidates = async (meetingId, filters) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const {
    skip, limit, startDate, endDate, filterOption
  } = filters;
  let searchQuery = `?skip=${skip}&limit=${limit}`;
  if (startDate) {
    searchQuery += `&startDate=${startDate}`;
  }
  if (endDate) {
    searchQuery += `&endDate=${endDate}`;
  }
  if (filterOption) {
    searchQuery += `&filterOption=${filterOption}`;
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/participants/myteam/${meetingId}/${searchQuery}`, options);
    return {
      status: 200,
      participants: response.data.result,
      message: response.data.message,
    };
  } catch (error) {
    console.error(error);
    toast.error(`${error.response.data.message}`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return {
      status: 400, participants: '', message: error.response.data.message
    };
  }
};

export default {
  createMeeting,
  convertTimeTOAMPM,
  meetingList,
  meetingDetails,
  getDropDownlist,
  createParticipant,
  participantList,
  participantsTrend,
  myTeamCandidates,
};
