import React, { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  ListItem
} from '@material-ui/core';
// import VideoLabelIcon from '@material-ui/icons/VideoLabel';
// import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  // Lock as LockIcon,
  Settings as SettingsIcon,
  // ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Monitor as TrainingIcon,
  // UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Activity as ActivityIcon,
  Briefcase as WorkIcon,
  Award as TaskIcon,
  // BookOpen,
  Youtube
  // Columns as MeetingRoom
} from 'react-feather';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
// import GroupIcon from '@material-ui/icons/Group';
// import ShareIcon from '@material-ui/icons/Share';
// import Wifi from 'src/components/Network/wifiSpeed';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import UserContext from 'src/contexts/UserContext';
import NavbarContext from 'src/contexts/NavbarContext';
import InputIcon from '@material-ui/icons/Input';
import { v4 as uuidv4 } from 'uuid';
import { useSocket } from 'src/contexts/SocketContext';
import NavItem from './NavItem';

const placeholder = '/static/images/avatars/user-logo-avtar.png';

// const user = {
//   avatar: userLoggedin.profile_pic
//     ? process.env.REACT_APP_SERVER_BASE_URL + userLoggedin.profile_pic : placeholder,
//   jobTitle: userLoggedin.company.your_level,
//   name: userLoggedin.name
// };

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logoutButton: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [user, SetUser] = useState({});
  const [userLoggedin, SetUserLoggedin] = useState(inLocalStorageJwt.getLoggedinUserData());
  const userPermissionsDSR = localStorage.getItem('dsr_permissions') ? JSON.parse(localStorage.getItem('dsr_permissions')) : {};
  // const [userStatus, setUserStatus] = useState({});
  const socket = useSocket();

  const basicUserItems = [
    {
      nested: 'no',
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard'
    },
    {
      nested: 'no',
      href: '/app/tasks',
      icon: TaskIcon,
      title: 'Tasks'
    },
    Object.prototype.hasOwnProperty.call(userPermissionsDSR, 'read') && userPermissionsDSR.read === true
    && {
      nested: 'no',
      href: '/app/dailyReports',
      icon: ActivityIcon,
      title: 'Daily Reports'
    },
    {
      nested: 'no',
      href: '/app/my-profile/view/',
      icon: UserIcon,
      title: 'My Profile'
    },
    {
      nested: 'no',
      href: '/app/account',
      icon: UserIcon,
      title: 'Account'
    },
    {
      nested: 'yes',
      title: 'Settings',
      icon: SettingsIcon,
      nestedPage: [{
        href: '/app/settings/security',
        icon: AlertCircleIcon,
        title: 'Security'
      },
      {
        href: '/app/settings/username',
        icon: AlertCircleIcon,
        title: 'Change Username'
      }]
    },
    // {
    //   nested: 'no',
    //   href: '/app/share',
    //   icon: ShareIcon,
    //   title: 'Share & Earn'
    // },
    // {
    //   nested: 'no',
    //   href: '/social/profile',
    //   icon: ShareIcon,
    //   title: 'Social Media'
    // },
    // {
    //   nested: 'no',
    //   href: `/meeting/room?callerId=${userLoggedin.email}`,
    //   icon: MeetingRoom,
    //   title: 'My Meeting Room'
    // },
  ];

  const learnerItems = [
    {
      nested: 'no',
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard'
    },
    {
      nested: 'no',
      href: '/app/tasks',
      icon: TaskIcon,
      title: 'Tasks'
    },
    // {
    //   nested: 'no',
    //   href: '/app/books',
    //   icon: BookOpen,
    //   title: 'Books'
    // },
    {
      nested: 'no',
      href: '/app/account',
      icon: UserIcon,
      title: 'Account'
    },
    {
      nested: 'yes',
      title: 'Settings',
      icon: SettingsIcon,
      nestedPage: [{
        href: '/app/settings/security',
        icon: AlertCircleIcon,
        title: 'Security'
      },
      {
        href: '/app/settings/username',
        icon: AlertCircleIcon,
        title: 'Change Username'
      }]
    },
    // {
    //   nested: 'no',
    //   href: '/app/share',
    //   icon: ShareIcon,
    //   title: 'Share & Earn'
    // },
    // {
    //   nested: 'no',
    //   href: '/social/profile',
    //   icon: ShareIcon,
    //   title: 'Social Media'
    // },
    // {
    //   nested: 'no',
    //   href: `/meeting/room?callerId=${userLoggedin.email}`,
    //   icon: MeetingRoom,
    //   title: 'My Meeting Room'
    // },
  ];

  const paidUserItems = [
    {
      nested: 'no',
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard'
    },
    // {
    //   nested: 'no',
    //   href: '/app/lms',
    //   icon: BarChartIcon,
    //   title: 'LMS'
    // },
    {
      nested: 'no',
      href: '/app/cms',
      icon: BarChartIcon,
      title: 'CMS'
    },
    {
      nested: 'no',
      href: 'work-application/list',
      icon: WorkIcon,
      title: 'Application Form'
    },
    {
      nested: 'no',
      href: '/app/customers',
      icon: UsersIcon,
      title: 'My Partners'
    },
    user.role === 'admin' && {
      nested: 'no',
      href: '/app/app-users',
      icon: UsersIcon,
      title: 'App Users'
    },
    user.role === 'admin' && {
      nested: 'no',
      href: '/app/coupons',
      icon: LocalOfferIcon,
      title: 'Coupons'
    },
    // {
    //   nested: 'no',
    //   href: '/app/meetings',
    //   icon: MeetingRoomIcon,
    //   title: 'Meeting-Room'
    // },
    {
      nested: 'yes',
      title: 'Videos',
      icon: Youtube,
      nestedPage: [{
        href: '/app/tcategories',
        icon: TrainingIcon,
        title: 'Trainings'
      }, {
        href: '/app/tasks',
        icon: TaskIcon,
        title: 'Tasks'
      }]
    },
    // {
    //   nested: 'no',
    //   href: '/app/tcategories',
    //   icon: TrainingIcon,
    //   title: 'Trainings'
    // },
    // {
    //   nested: 'no',
    //   href: '/app/books',
    //   icon: BookOpen,
    //   title: 'Books'
    // },
    // {
    //   nested: 'no',
    //   href: '/app/tasks',
    //   icon: TaskIcon,
    //   title: 'Tasks'
    // },
    {
      nested: 'no',
      href: '/app/dailyReports',
      icon: ActivityIcon,
      title: 'Daily Reports'
    },
    user.role === 'admin' && {
      nested: 'no',
      href: '/app/groups',
      icon: ActivityIcon,
      title: 'Groups'
    },
    // {
    //   nested: 'no',
    //   href: '/app/products',
    //   icon: ShoppingBagIcon,
    //   title: 'My Store'
    // },
    // {
    //   nested: 'no',
    //   href: '/app/my-profile/view/',
    //   icon: UserIcon,
    //   title: 'My Profile'
    // },
    // {
    //   nested: 'no',
    //   href: '/app/account',
    //   icon: UserIcon,
    //   title: 'Account'
    // },
    // {
    //   nested: 'no',
    //   href: `/meeting/room?callerId=${userLoggedin.email}`,
    //   icon: MeetingRoom,
    //   title: 'My Meeting Room'
    // },
    // {
    //   nested: 'no',
    //   href: '/app/settings',
    //   icon: SettingsIcon,
    //   title: 'Settings'
    // },
    // {
    //   href: '/login',
    //   icon: LockIcon,
    //   title: 'Login'
    // },
    // {
    //   href: '/register',
    //   icon: UserPlusIcon,
    //   title: 'Register'
    // },
    // {
    //   href: '/404',
    //   icon: AlertCircleIcon,
    //   title: 'Error'
    // },
    {
      nested: 'yes',
      title: 'Settings',
      icon: SettingsIcon,
      nestedPage: [{
        href: '/app/account',
        icon: UserIcon,
        title: 'Account'
      }, {
        href: '/app/my-profile/view/',
        icon: UserIcon,
        title: 'My Profile'
      }, {
        href: '/app/settings/security',
        icon: AlertCircleIcon,
        title: 'Security'
      },
      {
        href: '/app/settings/username',
        icon: AlertCircleIcon,
        title: 'Change Username'
      }]
    },
    // {
    //   nested: 'no',
    //   href: '/app/share',
    //   icon: ShareIcon,
    //   title: 'Share & Earn'
    // },
    // {
    //   nested: 'no',
    //   href: '/social/profile',
    //   icon: ShareIcon,
    //   title: 'Social Media'
    // },
    // {
    //   nested: 'no',
    //   href: '/app/groups',
    //   icon: GroupIcon,
    //   title: 'Groups'
    // },
    // {
    //   nested: 'no',
    //   href: `/app/${userLoggedin.email}`,
    //   icon: GroupIcon,
    //   title: 'MySite'
    // },
    // {
    //   nested: 'no',
    //   href: '/app/meeting-passes',
    //   icon: ConfirmationNumberIcon,
    //   title: 'Create Meeting Pass'
    // },
  ];
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // console.log(userContext.userData);
    SetUserLoggedin(inLocalStorageJwt.getLoggedinUserData());
    // SetUser(userLoggedin);
  }, [userContext.userData]);

  useEffect(() => {
    SetUserLoggedin(inLocalStorageJwt.getLoggedinUserData());
    SetUser(userLoggedin);
    // console.log(userLoggedin, user);
  }, []);

  useEffect(() => {
    SetUser(userLoggedin);
  }, [userLoggedin]);
  const updateNav = (udata) => {
    console.log(udata);
  };
  const SuperRoles = ['paid_user', 'admin', 'bms_promotor'];
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.profile_pic ? user.profile_pic : placeholder}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      {/* <Box p={2}>
        <Wifi />
      </Box> */}
      <Box p={2}>
        {
          user && SuperRoles.indexOf(user.role) > -1
            && (
              <List>
                {paidUserItems.map((item, i) => (
                  <NavItem
                    key={uuidv4()}
                    item={item}
                    index={i}
                    icon={item.icon}
                  />
                ))}
                <ListItem
                  key={uuidv4()}
                  disableGutters
                  className={classes.menuItem}
                >
                  <Button
                    className={classes.button}
                    color="inherit"
                    onClick={() => {
                      socket.disconnect(userLoggedin.id);
                      inLocalStorageJwt.logout().bind(this);
                    }}
                  >
                    <InputIcon
                      className={classes.icon}
                      size="20"
                    />
                    <span className={classes.title}>
                      Logout
                    </span>
                  </Button>
                </ListItem>
              </List>
            )
        }
        {
          user && (user.role === 'user' || user.role === 'guest')
          && (
          <List>
            {basicUserItems.map((item, i) => (
              <NavItem
                key={uuidv4()}
                item={item}
                index={i}
                icon={item.icon}
              />
            ))}
            <ListItem
              key={uuidv4()}
              disableGutters
              className={classes.menuItem}
            >
              <Button
                className={classes.button}
                color="inherit"
                onClick={() => {
                  socket.disconnect(userLoggedin.id);
                  inLocalStorageJwt.logout().bind(this);
                }}
              >
                <InputIcon
                  className={classes.icon}
                  size="20"
                />
                <span className={classes.title}>
                  Logout
                </span>
              </Button>
            </ListItem>
          </List>
          )
        }
        {
          user && user.role === 'learner'
          && (
          <List>
            {learnerItems.map((item, i) => (
              <NavItem
                key={uuidv4()}
                item={item}
                index={i}
                icon={item.icon}
              />
            ))}
            <ListItem
              key={uuidv4()}
              disableGutters
              className={classes.menuItem}
            >
              <Button
                className={classes.button}
                color="inherit"
                onClick={() => {
                  socket.disconnect(userLoggedin.id);
                  inLocalStorageJwt.logout().bind(this);
                }}
              >
                <InputIcon
                  className={classes.icon}
                  size="20"
                />
                <span className={classes.title}>
                  Logout
                </span>
              </Button>
            </ListItem>
          </List>
          )
        }
      </Box>
      <Box flexGrow={1} />
      {
          user && (user.role === 'user' || user.role === 'guest')
          && (
            <Box
              p={2}
              m={2}
              bgcolor="background.dark"
            >
              <Typography
                align="center"
                gutterBottom
                variant="h4"
              >
                Need more?
              </Typography>
              <Typography
                align="center"
                variant="body2"
              >
                Upgrade to PRO version and access more features
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                mt={2}
              >
                <Button
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                  variant="contained"
                >
                  See PRO version
                </Button>
              </Box>
            </Box>
          )
      }
    </Box>
  );
  const navbarContextVal = {
    updateNav
  };
  return (
    <NavbarContext.Provider value={navbarContextVal}>
      <>
        <Hidden lgUp>
          <Drawer
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
      </>
    </NavbarContext.Provider>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
