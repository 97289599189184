/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// @material-ui/icons

// core components
import Header from "src/components/Header/Header.js";
import Footer from "src/components/Footer/Footer.js";
import GridContainer from "src/components/Grid/GridContainer.js";
import GridItem from "src/components/Grid/GridItem.js";
import HeaderLinks from "src/components/Header/HeaderLinks.js";
import Parallax from "src/components/Parallax/Parallax.js";
import Pricing from './Pricing';
import styles from "src/assets/jss/material-kit-react/views/landingPage.js";
import landingbg from "src/assets/img/pricing-bg.jpeg";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="BMS GLOBAL"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax normal filter image={ landingbg }>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} key={Math.random()}>
              <h1 className={classes.title} style={{ 'fontSize': '4em' }}>We Manage, You Grow.</h1>
              <h4 style={{ 'fontSize': '1.125rem', 'fontWeight': '300', 'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                Discover the ideal software plan for your needs! Whether you're a solo entrepreneur or a large enterprise, our affordable pricing offers premium features and 24/7 support. Boost productivity and unlock your business's potential with flexible, scalable solutions tailored to your goals. Choose your plan and start innovating today!
              </h4>
              <br />
              <Button
                className={classes.danger}
                color="danger"
                size="lg"
                href="https://rzp.io/l/TIX4QpBx"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Quick Pay
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {/* <ProductSection /> */}
          <Pricing />
        </div>
      </div>
      <Footer />
    </div>
  );
}
